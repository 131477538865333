<template>
  <div class="row">
    <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
      <c-card title="개선 전 사진" class="cardClassDetailForm">
        <template slot="card-detail">
          <div class="col-12">
            <c-upload-picture
              height="400px"
              :attachInfo="attachInfo1"
              label="원거리"
              :editable="editable&&!disabled">
            </c-upload-picture>
          </div>
        </template>
      </c-card>
    </div>
    <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
      <c-card title="개선 후 사진" class="cardClassDetailForm">
        <template slot="card-detail">
          <div class="col-12">
            <c-upload-picture
              height="400px"
              :attachInfo="attachInfo2"
              label="근거리"
              :editable="editable&&!disabled">
            </c-upload-picture>
          </div>
        </template>
      </c-card>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import { uid } from 'quasar'
export default {
  name: 'near-relation-picture',
  props: {
    tabParam: {
      type: Object,
      default: () => ({
        iimAccidentId: '',
        accidentStatusCd: '',
      }),
    },
    saveCall: {
      type: Object,
      default: function() {
        return {
          saveCallData: '',
        }
      }
    },
    attachInfo1: {
      type: Object,
      default: function() {
        return {
          isSubmit: '',
          taskClassCd: 'NEAR_FIRST_PICTURE',
          taskKey: '',
        }
      },
    },
    attachInfo2: {
      type: Object,
      default: function() {
        return {
          isSubmit: '',
          taskClassCd: 'NEAR_SECOND_PICTURE',
          taskKey: '',
        }
      },
    },
  },
  data() {
    return {
      editable: true,
      popupOptions: {
        target: null,
        title: "",
        visible: false,
        top: "",
        param: {},
        closeCallback: null,
      },
    };
  },
  watch: {
    'saveCall.saveCallData'() {
      this.attachInfo1.isSubmit = uid()
      this.attachInfo2.isSubmit = uid()
    },
  },
  computed: {
    disabled() {
      return this.editable && Boolean(this.tabParam.accidentStatusCd) && this.tabParam.accidentStatusCd !== 'ISNC000001'
    }
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
    },
  }
};
</script>
